import React, { useState, useEffect } from "react";
import { useAtom } from "jotai";
import axios from "axios";
import FormLayout from "../components/FormLayout";
import SubmitButton from "../components/SubmitButton";
import { useNavigate } from "react-router-dom";
import { postDataAtom, responseTimeAtom } from "../store/atoms";
import { desiredCompanies } from "./../data/desiredCompanies";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import DatePicker from "../components/organisms/DatePicker";

const InquiryForm = () => {
  const [postData, setPostData] = useAtom(postDataAtom);
  const [responseTime, setResponseTime] = useAtom(responseTimeAtom);
  const navigate = useNavigate();

  const [nameError, setNameError] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [birthError, setBirthError] = useState("");
  const [desiredCompaniesError, setdesiredCompaniesError] = useState("");
  const [selectedOptions, setSelectedOptions] = React.useState([]);

  useEffect(() => {
    // ローカルストレージから値を取得
    const storedPostData = localStorage.getItem("postData");
    const storedResponseTime = localStorage.getItem("responseTime");
    if (storedPostData && storedResponseTime) {
      // 値が存在する場合、jotaiのstateを更新
      setPostData(JSON.parse(storedPostData));
      setResponseTime(storedResponseTime);
    } else {
      // 値が存在しない場合、/answerページにリダイレクト
      navigate("/answer");
    }
  }, [setPostData, navigate]);

  const animatedComponents = makeAnimated();
  const selectEleStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      borderColor: "bg-neutral-700",
    }),
  };

  const options = desiredCompanies.map((company) => {
    return { value: company, label: company };
  });

  const handleChange = (event) => {
    const values = event.map((option) => option.value);
    setSelectedOptions(event);

    setPostData({
      ...postData,
      user: {
        ...postData.user,
        desiredCompanies: [],
      },
    });

    setPostData({
      ...postData,
      user: {
        ...postData.user,
        desiredCompanies: [...values],
      },
    });
  };

  const validateForm = async (e) => {
    e.preventDefault();
    let isError = false;

    if (!postData.userAnswer.result.trim()) {
      isError = true;
    }
    if (!postData.userAnswer.reason.trim()) {
      isError = true;
    }

    if (!postData.user.name.trim()) {
      setNameError("名前を入力してください。");
      isError = true;
    } else {
      setNameError("");
    }

    if (!postData.user.companyName.trim()) {
      setCompanyNameError("会社名を入力してください。");
      isError = true;
    } else {
      setCompanyNameError("");
    }

    if (!postData.user.email.trim()) {
      setEmailError("メールアドレスを入力してください。");
      isError = true;
    } else {
      setEmailError("");
    }
    if (!postData.user.phone.trim()) {
      setPhoneError("電話番号を入力してください。");
      isError = true;
    } else {
      setPhoneError("");
    }

    if (!postData.user.birth.trim()) {
      setBirthError("生年月日を入力してください。");
      isError = true;
    } else {
      setBirthError("");
    }

    if (!postData.user.desiredCompanies.length > 0) {
      setdesiredCompaniesError("志望企業を選択してください。");
      isError = true;
    } else {
      setdesiredCompaniesError("");
    }

    if (!isError) {
      const data = {
        index: postData.index,
        user_answer: {
          result: postData.userAnswer.result,
          reason: postData.userAnswer.reason,
        },
        user_name: postData.user.name,
        company_name: postData.user.companyName,
        mail_address: postData.user.email,
        telephone_number: postData.user.phone,
        birth: postData.user.birth,
        desired_companies: postData.user.desiredCompanies,
        job_change: "Yes",
        answer_time: localStorage.getItem("responseTime"), // responseTime
      };

      try {
        await axios.post("/api/service2", data);

        // ローカルストレージの値を削除
        localStorage.removeItem("postData");
        localStorage.removeItem("responseTime");

        navigate("/thank-you-message");
      } catch (error) {
        console.error("送信エラー:", error);
      }
    }
  };

  const handleDateChange = (date) => {
    if (postData.user.birth !== date) {
      setPostData({
        ...postData,
        user: { ...postData.user, birth: date },
      });
    }
  };

  return (
    <FormLayout>
      <h2 className="text-left text-4xl font-bold mb-6">採点結果を送信</h2>
      <form onSubmit={validateForm} className="w-full">
        {/* 名前の入力フィールド */}
        <div className="mb-4">
          <label htmlFor="name" className="block text-gray-700 mb-2">
            <span className="bg-neutral-700 text-white rounded-md px-2 py-1 mr-2">
              必須
            </span>
            <span className="font-bold">名前</span>
          </label>
          <input
            value={postData.user.name}
            onChange={(e) =>
              setPostData({
                ...postData,
                user: { ...postData.user, name: e.target.value },
              })
            }
            type="text"
            id="name"
            name="name"
            placeholder="山田太郎"
            className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-gray-500"
          />
          {nameError && <p className="text-gray-500 text-sm">{nameError}</p>}
        </div>
        {/* 会社名の入力フィールド */}
        <div className="mb-4">
          <label htmlFor="companyName" className="block text-gray-700 mb-2">
            <span className="bg-neutral-700 text-white rounded-md px-2 py-1 mr-2">
              必須
            </span>
            <span className="font-bold">会社名</span>
          </label>
          <input
            value={postData.user.companyName}
            onChange={(e) =>
              setPostData({
                ...postData,
                user: { ...postData.user, companyName: e.target.value },
              })
            }
            type="text"
            id="companyName"
            name="companyName"
            placeholder="株式会社○○○"
            className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-gray-500"
          />
          {companyNameError && (
            <p className="text-gray-500 text-sm">{companyNameError}</p>
          )}
        </div>
        {/* メールアドレスの入力フィールド */}
        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-700 mb-2">
            <span className="bg-neutral-700 text-white rounded-md px-2 py-1 mr-2">
              必須
            </span>
            <span className="font-bold">メールアドレス</span>
          </label>
          <input
            value={postData.user.email}
            onChange={(e) =>
              setPostData({
                ...postData,
                user: { ...postData.user, email: e.target.value },
              })
            }
            type="email"
            id="email"
            name="email"
            placeholder="user@example.com"
            className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-gray-500"
          />
          {emailError && <p className="text-gray-500 text-sm">{emailError}</p>}
        </div>
        {/* 電話番号の入力フィールド */}
        <div className="mb-4">
          <label htmlFor="phone" className="block text-gray-700 mb-2">
            <span className="bg-neutral-700 text-white rounded-md px-2 py-1 mr-2">
              必須
            </span>
            <span className="font-bold">電話番号</span>
          </label>
          <input
            value={postData.user.phone}
            onChange={(e) =>
              setPostData({
                ...postData,
                user: { ...postData.user, phone: e.target.value },
              })
            }
            type="tel"
            id="phone"
            name="phone"
            placeholder="080-0000-0000"
            className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-gray-500"
          />
          {phoneError && <p className="text-gray-500 text-sm">{phoneError}</p>}
        </div>

        {/* 生年月日の入力フィールド */}
        <div className="mb-4">
          <label htmlFor="birth" className="block text-gray-700 mb-2">
            <div className="mb-2">
              <span className="bg-neutral-700 text-white rounded-md px-2 py-1 mr-2">
                必須
              </span>
              <span className="font-bold">生年月日</span>
            </div>

            <div className="w-full">
              <DatePicker onDateChange={handleDateChange} />
            </div>

            {birthError && (
              <p className="text-gray-500 text-sm">{birthError}</p>
            )}
          </label>
        </div>

        {/* 志望企業の入力フィールド */}
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">
            <span className="bg-neutral-700 text-white rounded-md px-2 py-1 mr-2">
              必須
            </span>
            <span className="font-bold">志望企業</span>

            <div className="mt-2">
              <Select
                value={selectedOptions}
                onChange={(event) => handleChange(event)}
                options={options}
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                styles={selectEleStyles}
                isOptionDisabled={() => selectedOptions.length >= 3}
                placeholder="志望企業を選択してください"
              />
            </div>

            {desiredCompaniesError && (
              <p className="text-gray-500 text-sm">{desiredCompaniesError}</p>
            )}
          </label>
        </div>
        <div className="text-left text-gray-600 text-sm mb-4">
          メールにて採点結果や改善のアドバイスをご連絡させていただきます。
        </div>

        <SubmitButton text="解答を送信する" />

        <div className="text-center mt-4 text-gray-700">
          <a
            href="https://remedy-tokyo.co.jp/privacy-protection"
            target="_blank"
            rel="noopener noreferrer"
            className="underline"
          >
            プライバシーポリシー
          </a>
          に同意する
        </div>
      </form>
    </FormLayout>
  );
};

export default InquiryForm;
