import React from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./index.css";
import AnswerForm from "./pages/AnswerForm";
import InquiryForm from "./pages/InquiryForm";
import reportWebVitals from "./reportWebVitals";
import ThankYouMessage from "./pages/ThankYouMessage";
import ThankYourInquiry from "./pages/ThankYourInquiry";
import Start from "./pages/Start";
import ScrollToTopOnPageChange from "./components/ScrollToTopOnPageChange"; // ScrollToTopOnPageChangeをimport
import FermiInquireForm from "./pages/FermiInquireForm";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      {/* ScrollToTopOnPageChangeコンポーネントをRouterの直下に配置 */}
      <ScrollToTopOnPageChange />

      <Routes>
        <Route path="/" element={<Start />} />
        <Route path="/answer" element={<AnswerForm />} />
        <Route path="/inquiry" element={<InquiryForm />} />
        <Route path="/thank-you-message" element={<ThankYouMessage />} />
        <Route path="/fermi-inquire" element={<FermiInquireForm />} />
        <Route path="/thank-your-inquiry" element={<ThankYourInquiry />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  </React.StrictMode>
);

reportWebVitals();
